/*================================
Template Name: Cowork
Author: Ram Nethaji
Author website: http://ramnethaji.com/
Version: 1.0
==================================

TABLE OF CONTENTS:

General
Font
Body
Global
Preloader
Navbar
Section
Header
Buttons
Border Styles
Features List
CTA
Blog
Footer


=================================== */

/*------------- General -------------*/

@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

/*------------- Font -------------*/
@import url("https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700,900&display=swap");

/*------------- Body -------------*/

body {
  font-family: "Maven Pro", sans-serif;
  font-size: 15px;
  background-color: #fff;
  color: #4d4d4d;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
}

/*------------- Global -------------*/
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}

/*custom margin css*/
.no-margin {
  margin: 0 !important;
}

.navbar-toggler-icon {
  /* background-image: url(../img/menu.svg); */
  background-color: #fff0d3;
  background-size: 45%;
  width: 2em;
  height: 1.6em;
  border-radius: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*custom padding css*/
.xs-padding-div {
  padding: 2em;
}
.sm-padding {
  padding: 4em 0;
}

.no-padding {
  padding: 0;
}

.padding-btm {
  padding-bottom: 4em;
  padding-top: 4em;
}
.padding-lg-top {
  padding-top: 10em;
}
.custom-select:focus {
  box-shadow: none !important;
  outline: none !important;
}

.search-form {
  float: right;
  width: 3em;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 1em;
  background-color: rgba(0, 0, 0, 0);
  background-image: url(../Headers/search-icon.png);
  background-position: 12px center;
  background-repeat: no-repeat;
  background-size: 18px;
  padding: 0.5em 0.2em 0.5em 2.8em;
  border-radius: 0;
  /* margin-top:.3em; */
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  color: #fff;
}
.search-form:focus {
  color: #ffac10;
  box-shadow: none;
  width: 230px;
  /* background-color: rgba(0,0,0,.9); */
  border: 1px solid #ffac10;
  padding: 0.5em 1em 0.5em 2.8em;
}
.search-form::placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.search-form::-moz-placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-bottom: 0;
}
.switch input {
  display: none;
}
.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .switch-slider {
  background-color: #2196f3;
}
input:focus + .switch-slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch-slider.round {
  border-radius: 34px;
}
.switch-slider.round:before {
  border-radius: 50%;
}

.navbar-brand {
  font-size: 2em;
  font-family: fantasy;
  font-weight: 500;
  color: #0108d9;
}

header .nav-link {
  font-weight: 500;
  font-size: 0.9em;
  position: relative;
  margin-left: 1em;
}

header .nav-link:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0.1em;
  background: #ffbd10;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #e89700, #ffbd10);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #e89700, #ffbd10);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  bottom: 0%;
  left: 50%;
  transition: all 500ms ease-in-out;
}

header .nav-link:hover:before {
  left: 0%;
  width: 100%;
}

.nav-search {
  display: block;
  padding: 0em 1em;
}
.nav-search .fa-search {
  background: #ffac0f;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 100%;
  font-size: 1em;
  color: #fff;
}

.user-link img {
  width: 2em;
  border-radius: 100%;
  height: 2em;
  object-fit: cover;
}

.user-link {
  margin-left: 1em;
}

.user-profile {
  margin-top: 0.3em;
}

.user-profile .dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.8em 1.5em;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 0.9em;
}

.user-profile .dropdown-menu .dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.forgot-modal {
  display: none;
}

.user-profile .dropdown-menu {
  padding: 0;
  min-width: 12em;
}

.main_logo {
  width: 8em;
}

.carousel-slider-main {
  padding: 1em;
  max-width: 1100px;
  position: relative;
}

.banner-slider .owl-carousel .owl-item img {
  border-radius: 0.3em;
}

.banner-slider .owl-nav button {
  background: #ffffff none repeat scroll 0 0 !important;
  border-radius: 30px;
  color: #ffac10 !important;
  display: inline-block;
  font-size: 1.5em !important;
  height: 3em;
  line-height: 40px;
  margin: 1em -1.7em;
  opacity: 0.8;
  text-align: center;
  text-transform: uppercase;
  width: 3em;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
}

.workspace-grid {
  position: absolute;
  right: -250px;
  top: calc(50% - 0em);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  width: calc(33.33333% - -2em);
}

.workspace-content {
  padding: 2em;
  background-color: #fff;
  border-radius: 0.3em;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}

.title_main {
  font-size: 2em;
  font-weight: 600;
  line-height: 2em;
}

.title {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.5rem;
}

.sub-title {
  font-size: 1em;
  font-weight: 500;
  opacity: 0.8;
  line-height: 1.8em;
}

.location_select label {
  font-size: 0.9em;
  font-weight: 500;
}

.location_select .input-group-text {
  background: #fff;
  border: 0.5px solid #666;
}

/*.location-label
{
    font-size: 0.8em;
    font-weight: 500;
    display: block;
    position: absolute;
    top: -8px;
    left: 12px;
    padding-left: .5em;
    color: #666;
    white-space: nowrap;
    z-index: 9;

}
.location-label:before
{
    content: '';
    width: calc(100% + .75rem / 2);
    height: 1.5em;
    background-color: #fff;
    position: absolute;
    top: 0em;
    left: 0em;
    z-index: -1
}*/
.workspace-card {
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: right;
  justify-content: space-between;
}

.workspace-card .card_img.wish-list {
  display: flex;
  flex-direction: column;
}

.single-space-btn {
  margin: 20px;
}

.card_details {
  display: flex;
  flex-direction: column;
}

.card_img img {
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  height: 13em;
  object-fit: cover !important;
}

.left_card_img {
  float: left;
  max-width: 13em;
}

.left_card_img img {
  border-top-left-radius: 0.3em !important;
  border-bottom-left-radius: 0.3em !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 13em;
  object-fit: cover !important;
}

.right_card_content {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 14.5em;
  padding-right: 2em;
}

.map-single-space {
  position: relative;
  width: 100%;
  height: 400px;
}

.card_details {
  padding: 1.5em;
}

.card-title {
  font-weight: 600;
  font-size: 1.1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-desc {
  font-size: 0.85em;
  font-weight: 400;
  line-height: 2em;
  opacity: 0.9;
  margin-bottom: 0.5em;
}

.wish-list {
  position: relative;
  height: 13em;
  overflow: hidden;
}

.single-space-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.workspace-card .card_img.similar-location-img {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 16em;
  overflow: hidden;
}

.workspace-card .card_img.similar-location-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.wish_list_icon {
  display: inline-block;
  font-size: 0.75em;
  height: 2em;
  line-height: 2em;
  position: absolute;
  right: 0.7em;
  text-align: center;
  top: 0.7em;
  width: 4em;
  color: #ffff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0.5em;
}

.rating i.active {
  color: #ffbc00;
}

.rating i {
  margin: 0.2em 0.5em 0.2em 0;
  /* color: #eae2d6; */
  font-size: 0.75em;
}

.border-title {
  background-size: 100%;
  width: 64px;
  height: 7px;
  /* background-image: url(../img/border.png); */
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
}

.card-content {
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
  padding: 2em;
  height: 100%;
  background-color: #f5f4f2;
  margin-top: 2em;
}

.card-icon img {
  width: 4em;
  margin-bottom: 1em;
}

.call-action {
  padding: 3em;
}

.call_action_title {
  font-size: 2em;
  font-weight: 600;
  color: #fff;
}

.call_action_desc {
  margin: 0;
  font-size: 0.9em;
  color: #fff;
  opacity: 0.8;
}

.map-position,
.contact,
.box-rent {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}

.map {
  border-radius: 0.3em;
}

.workspace-card.only-pricing {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  background-color: #f5f4f2;
  padding: 1em;
}

.workspace-card.only-pricing .border-left-price {
  border-left: 5px solid #ffac10;
  padding: 0 1em;
}

.price {
  font-size: 1em;
  font-weight: 500;
}

.count {
  display: inline-block;
}

.move-in {
  display: inline-block;
}

.view-location {
  font-size: 1.2em;
}

.address {
  margin: 0;
  font-weight: 500;
  font-size: 0.93em;
  line-height: 2em;
  opacity: 0.8;
}

/*radio button select*/

.custon-radio-button {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.9em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custon-radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custon-radio-button .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custon-radio-button:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.custon-radio-button input:checked ~ .checkmark {
  background-color: #007bff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custon-radio-button input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custon-radio-button .checkmark:after {
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}

.custom-control label {
  font-size: 0.85em;
  font-weight: 500;
}

.user-nav {
  font-size: 0.9em;
  font-weight: 500;
}

.user-link:hover {
  text-decoration: none;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 18em;
  padding: 2em 2em;
  margin: 0.8em 0 0;
  font-size: 1em;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 0.3em;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.count .input {
  width: 5em !important;
  border: 0.05px solid #cfcccc;
}

.count .input-group-spinner {
  max-width: 153px;
  margin: 0px auto;
}

.cancel,
.apply {
  color: #ffac10;
  font-size: 1em;
  font-weight: 500;
}

/*custom button css*/

.btn.btn-primary {
  background-color: #ffac10;
  color: #fff !important;
  border-color: #ffac10;
  padding: 0.8em 1.5em;
  font-weight: 500;
  font-size: 0.9em;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ffac10 !important;
  border-color: #ffac10 !important;
}

.btn.btn-primary:hover {
  background-color: #e89700;
  color: #fff;
}

.contact-link {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: #ffac10;
  font-size: 0.8em;
  font-weight: 500;
  margin-top: 1em;
}

.contact-link .banner-hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  color: #ffac10;
  font-size: 1.5em;
  margin-left: 0.3em;
  vertical-align: middle;
}

.contact-link:hover {
  color: #ffac10 !important;
}

.contact-link:hover .banner-hvr-icon,
.contact-link:focus .banner-hvr-icon,
.contact-link:active .banner-hvr-icon {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn {
  border-radius: 0.3em;
}

.btn.btn-white {
  font-size: 0.9em;
  background-color: #fff;
  font-weight: 500;
  padding: 0.7em 2em;
}

.btn.btn-white:hover {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.btn.btn-outline-primary {
  background-color: transparent;
  background-image: none;
  border-color: #ffac10;
  font-size: 0.9em;
  font-weight: 500;
  padding: 0.8em 1.5em;
  color: #ffac10;
}

.btn.btn-outline-primary:hover {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

/*custom background css*/

.bg-color1 {
  background-color: #252531;
}

.bg-color2 {
  background-color: #1d1d25;
}

.bg-color3 {
  background-color: #ffac10;
}
.bg-color4 {
  background-color: #fafafa;
}

/*footer section*/
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.footer_logo {
  font-size: 2em;
  font-weight: 600;
  color: #fff;
}

.footer_desc {
  color: #bababa;
  font-size: 0.9em;
  line-height: 2em;
  opacity: 0.8;
}

.footer_title {
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1em;
}

.footer_list ul li {
  list-style: none;
  margin-top: 0.7em;
}

.footer_list ul li a {
  color: #bababa;
  font-size: 0.82em;
  opacity: 0.9;
}

.footer_list ul li a:hover {
  color: #fff;
  text-decoration: underline;
}

.sub_footer {
  padding: 2em;
}

.copyright {
  font-size: 0.8em;
  color: #fff;
  font-weight: 500;
  margin: 0;
}

.social__footer li {
  color: #c7c6c6;
  margin-right: 1.5em;
  font-size: 0.9em;
  display: inline-flex;
}

.social__footer li:hover {
  color: #ffff;
}

.social__footer {
  list-style: none;
  padding: 0;
}

.border-radius {
  border-radius: 0.3em;
}

.tab-images {
  height: 4em !important;
}

.tab-big-img {
  border-radius: 0.3em;
}

#tab-img-slider .nav-tabs .nav-link {
  border: 0;
}

#tab-img-slider .nav-tabs {
  border-bottom: 0;
}

#tab-img-slider .nav-tabs .nav-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#tab-img-slider .nav-link.active {
  border-bottom: 0;
  width: 5em;
  padding: 1em 0;
  border-bottom: 0 !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

#tab-img-slider #carousel-example-generic {
  margin: 0px auto;
  width: 400px;
}

#tab-img-slider #carousel-custom {
  margin: 0px auto;
  box-shadow: none;
}

#tab-img-slider #carousel-custom .carousel-indicators {
  margin: 10px 0 0;
  overflow: hidden;
  position: static;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

#tab-img-slider #carousel-custom .carousel-indicators li {
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  height: auto;
  margin: 0 !important;
  width: auto;
}

#tab-img-slider #carousel-custom .carousel-indicators li img {
  display: block;
  opacity: 0.3;
  cursor: pointer;
}

#tab-img-slider #carousel-custom .carousel-indicators li.active img {
  opacity: 1;
}

#tab-img-slider #carousel-custom .carousel-indicators li:hover img {
  opacity: 0.75;
}

#tab-img-slider .thumb-img {
  height: 5em;
}

#tab-img-slider .carousel-control-next,
.carousel-control-prev {
  top: -60px !important;
}

#tab-img-slider #carousel-custom .carousel .slide img {
  height: 30em;
  object-fit: cover;
}

.left-icon,
.right-icon {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  width: 4em;
  height: 4em;
  line-height: 4em;
  color: #ffac10;
  font-weight: 600;
  border-radius: 100%;
}

.right-icon {
  /* display: none;*/
}

.carousel-control-prev {
  left: -45px;
}

.carousel-control-next {
  right: -45px;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 0.9 !important;
}

/*contact form*/

.contact .form-control {
  padding: 1em 1em;
  font-size: 0.85em;
  border: 0;
}

.contact-form .form-control {
  padding: 1em 1em;
  font-size: 0.85em;
  border: 0.5px solid #e8e8e8;
}
.contact-form span {
  font-size: 0.85em;
  font-weight: 400;
}
.form-control:focus {
  box-shadow: none;
  outline: #0600ff;
}

.contact {
  background: rgba(0, 0, 0, 0.02);
  padding: 2em;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.3em;
}

.contact .btn.btn-decrement {
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  background-color: #cbcbcb;
  color: #fff;
}

.contact .btn.btn-increment {
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  background-color: #cbcbcb;
  color: #fff;
}

.contact .input {
  width: 5em !important;
  border: 0.5px solid #f6f4f4;
}

.contact label,
.contact-form label,
.review-form label {
  font-size: 0.9em;
  font-weight: 500;
}

/*move in date*/

.custom-select {
  position: relative;
  /* padding: 0 !important; */
  border: 0 !important;
  height: 45px;
  font-size: 0.9em;
  border: 0 !important;
  position: relative;
}

.special {
  font-weight: bold !important;
  color: #fff !important;
  background: #a055ce !important;
  text-transform: uppercase;
}
option:hover {
  background: #a055ce !important;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: #fff;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 19px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #767676 transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #000 transparent;
  top: 10px;
}

/*style the items (options), including the selected item:*/
.select-items div {
  color: #000;
  padding: 0.8em 1em;
  cursor: pointer;
  user-select: none;
  font-size: 0.8em;
  font-weight: 500;
}

.select-selected {
  padding: 1em 1em;
  cursor: pointer;
  user-select: none;
  font-size: 0.85em;
  border-radius: 0.3em;
  border: 0.5px solid #e8e8e8;
}

.border-none .select-selected {
  border: 0 !important;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border: 0.5px solid #e8e8e8;
  border-radius: 0.3em;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.amenities {
  background-color: #f9f9f9;
  padding: 2em;
  border-radius: 0.3em;
  height: 100%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
}

.amenities-img img{
  height: 6em;
  object-fit: cover;
}

.amenities-title,
.sub-head {
  font-weight: 500;
  font-size: 1em;
  margin-top: 1em;
  line-height: 1.5em;
}

.sub-desc {
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.8em;
}

.table tbody tr td {
  font-size: 0.8em;
  font-weight: 500;
  text-align: center;
}

/*payment*/
.payment-method .radiobtn {
  position: relative;
}

.payment-method .radiobtn input[type="checkbox"],
.payment-method .radiobtn input[type="radio"] {
  display: none;
  position: absolute;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.payment-method .radiobtn input[type="checkbox"]:checked + label,
.payment-method .radiobtn input[type="radio"]:checked + label {
  background: #fff;
  -webkit-animation-name: blink;
  animation-name: blink;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  border-color: #ffac10;
  padding: 2em 2em;
  margin-left: 0em;
}

.payment-method .radiobtn label {
  display: block;
  color: #6b778c;
  border: 1px solid #dfe1e6;
  border-radius: 2px;
  font-size: 0.8em;
  cursor: pointer;
  padding: 2em 2em;
  background-color: #fff;
  line-height: 1.15;
  font-weight: 500;
}

.payment-method .radiobtn input[type="checkbox"]:checked + label:before,
.payment-method .radiobtn input[type="radio"]:checked + label:before {
  width: 14px;
  height: 14px;
  border-color: #ffac10;
}

.payment-method .radiobtn label:before {
  background: transparent;
  z-index: 2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  width: 0;
  height: 0;
  background-size: 7px;
}

.payment-method .radiobtn label:after,
.payment-method .radiobtn label:before {
  content: "";
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #dfe1e6;
}

.payment-method .radiobtn input[type="checkbox"]:checked + label:after,
.payment-method .radiobtn input[type="radio"]:checked + label:after {
  background: #ffac10;
}

.payment-method .radiobtn img {
  width: 3.5em;
  margin-right: 1em;
}

.card-pay label {
  font-size: 0.9em;
  font-weight: 500;
}

.card-pay .form-control {
  font-size: 0.75em;
  border: 1px solid #eaeaea;
  padding: 1em 1.5em;
}

.form-control {
  resize: none;
}

.card-pay {
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 2em;
  margin-top: 1.5em;
  border-radius: 0.3em;
}

.card-label {
  padding: 3.2em !important;
}

.paid-details {
  border: 1px solid #ffbd10;
  padding: 0.7em;
  border-radius: 0.3em;
}

/*summary payment*/
.booking-total {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.3em;
}

.summary-booking {
  padding: 0.8em;
  background-color: #ffac10;
  text-align: center;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}

.summary-booking h5 {
  font-size: 0.8em;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  margin: 0;
}

.package-price {
  padding: 2em;
}

.package-price h5 {
  font-size: 0.93em;
  font-weight: 500;
  margin: 0;
}

.package-price p {
  font-size: 0.8em;
  font-weight: 400;
  margin: 0;
  opacity: 0.9;
}

.extra-fee {
  font-size: 0.75em !important;
  font-weight: 400 !important;
  color: #9e9e9e;
}

p {
  margin: 0;
}

.border-light {
  border-bottom-style: dotted;
  border-color: #cbcbcb !important;
  border-width: 0.07em;
}

.payable-amount {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  font-size: 1.1em;
}

.suggestion .form-control {
  border: 0;
  background: #daefe4;
  resize: none;
  font-size: 0.7em;
  border-radius: 0;
}

.package-price label {
  font-size: 0.92em;
  font-weight: 500;
}

.package-price .form-control {
  font-size: 0.8em;
  padding: 0.8em 1em;
}

.failure,
.success {
  width: 30em;
}

.payment-title {
  font-size: 1.5em;
}

/*user details tab css*/

.user-details .nav.nav-tabs {
  float: left;
  display: block;
  margin-right: 20px;
  border-bottom: 0;
  border-right: 1px solid #ddd;
  padding-right: 15px;
}

.user-details .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #ededed;
}

.user-details .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #ffbd10 !important;
  border-color: transparent !important;
}

.user-details .nav-tabs .nav-link:hover {
  color: #ffffff;
  background-color: #ffbd10 !important;
}

.user-details .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 0.3em;
  margin-bottom: 1em;
  padding: 1em;
  font-size: 0.9em;
  font-weight: 500;
}

.user-details .tab-content > .active {
  display: block;
  background: #fff;
  min-height: 300px;
}

.user-details .nav.nav-tabs {
  float: left;
  display: block;
  margin-right: 20px;
  border-bottom: 0;
  border-right: 1px solid transparent;
  padding-right: 15px;
  width: 240px;
}

.del-text {
  font-size: 0.8em;
}

/*image upload*/

.prev-img-blk {
  width: 15em;
  height: 13em;
  overflow: hidden;
  position: relative;
}

.prev-img-blk .img_outer {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #999;
  background-size: cover;
  background-position: center center;
}

.prev_preview {
  width: auto;
  height: 100%;
}

.up-btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.prev_outer {
  padding: 2em;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}

.add-payment {
  font-size: 1em;
  font-weight: 500;
  border: 1px solid #e6e6e6;
  padding: 2em;
  border-radius: 0.3em;
  cursor: pointer;
}

/*car payment*/

.card-pay label {
  font-size: 0.9em;
  font-weight: 500;
}

.card-pay .form-control,
.paypal-acc .form-control {
  font-size: 0.75em;
  border: 0.5px solid #dee2e6;
  border-radius: 0.3em;
  background: transparent;
  padding: 1em 1em;
}

.card-pay,
.paypal-acc {
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 2em;
  margin-top: 1.5em;
}

.card-img img {
  width: 25em;
}

.profile-card {
  padding: 3em;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  max-width: 700px;
  margin: 0px auto;
  border-radius: 0.3em;
  background-color: rgba(0, 0, 0, 0.003);
}

.profile-img img {
  width: 5em;
  border-radius: 100%;
  height: 5em;
  object-fit: cover;
}

.profile-img {
  float: left;
}

.profile-details {
  padding-left: 7em;
}

.user-name {
  font-size: 1em;
  font-weight: 600;
}

.edit {
  font-size: 0.8em;
  font-weight: 500;
  color: #3f00ff;
}

.orange {
  color: #ffac10;
}

.orange:hover {
  color: #cd8600;
  text-decoration: underline;
}

/*selector*/
.location_select .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #ccc;
  padding: 1em;
}

.location_select .input-group > .input-group-prepend > .input-group-text:focus {
  outline: none !important;
  box-shadow: unset !important;
  border-color: #ccc;
}

.location_select .form-control:focus {
  outline: none !important;
  box-shadow: unset !important;
  border-color: #ccc;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 20px;
  position: absolute;
  top: 19px;
  right: 10px;
  width: 20px;
}

.select2-container--default .select2-selection--single:focus {
  outline: none !important;
  box-shadow: none;
}

.select2-container--default .select2-selection--single {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  /*border: 1px solid #f0f0f0;*/
  font-size: 0.9em;
  font-weight: 500;
  opacity: 0.6;
}

.select2-container {
  width: 297px !important;
  background: transparent;
  padding: 1em;
  border: 1px solid #666666;
  border-left: 0;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
}

.selection2-selection {
  box-shadow: none !important;
  outline: none !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #000;
  font-size: 0.9em;
}

.select2-container--focus:focus {
  box-shadow: none !important;
  outline: none !important;
}

.top-categories-search .form-control {
  border: medium none;
  font-size: 0.8em;
  min-width: 384px;
  background-color: #f0f0f0;
}

.select2-dropdown {
  border: 0.5px solid #666666;
  border-radius: 0.3em !important;
  background-color: #ffffff;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #d3d3d3 !important;
  background: #ffffff !important;
  color: #000 !important;
  font-size: 0.85em;
  border-radius: 0.3em;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #454545;
  color: #fff;
  font-size: 0.8em;
}

.select2-results__option {
  padding: 1em;
  user-select: none;
  -webkit-user-select: none;
  font-size: 0.85em;
}

.select2-container--open .select2-dropdown {
  left: -36px;
  top: -1px;
  width: 333px !important;
}

/*our number says*/

.our-number-list {
  padding: 2em;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  text-align: center;
  background-color: #f5f4f2;
  margin-top: 2em;
}

.our-number-list:last-child {
  border: none;
}

.outer-margin {
  margin-top: 3em;
}

.count_up_stat,
.inc {
  font-size: 1.5em;
  font-weight: 800;
}

.our-numbers__info {
  font-size: 1em;
  font-weight: 500;
  color: #ffac10;
}

/* Icon Wobble Horizontal */

@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(12px);
    transform: translateX(12px);
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.hvr-icon-wobble-horizontal:focus {
  box-shadow: none;
}

.hvr-icon-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: #fff;
  font-size: 0.8em;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
}

.hvr-icon-wobble-horizontal .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  color: #fff;
  font-size: 1.5em;
  margin-left: 0.3em;
}

.hvr-icon-wobble-horizontal:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

.hvr-icon-wobble-horizontal:hover .hvr-icon,
.hvr-icon-wobble-horizontal:focus .hvr-icon,
.hvr-icon-wobble-horizontal:active .hvr-icon {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/*modal css*/

.modal-title {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 0.2em;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-body {
  padding: 0em 3em 3em;
}

.col-form-label {
  font-size: 0.9em;
  font-weight: 500;
}

.modal-body .form-control {
  font-size: 0.8em;
  padding: 0.7em 1em;
}

.forgot {
  font-size: 0.8em;
  color: red;
  text-decoration: underline;
}

.signup-img {
  padding-top: 6em;
}

/*review css*/
.user-img img {
  max-width: 6em;
  height: 5em;
  border-radius: 10px;
  object-fit: cover;
}

.user-img {
  float: left;
}

.review {
  padding-left: 7em;
}

.user-review {
  padding: 1em;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
}

.user-review h5 {
  font-size: 0.9em;
  font-weight: 500;
}

.review-date {
  font-size: 0.8em;
}

.title-head {
  font-weight: 600;
  font-size: 1.3em;
}

.review-form .form-control {
  font-size: 0.8em;
  padding: 1em 1em;
}

.booking-details {
  padding-left: 7em;
}

.user-booking-list {
  padding: 1em;
  background-color: #f6f6f6;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0.3em;
  max-width: 900px;
  margin: 0px auto;
  margin-bottom: 2em;
}

.back-btn {
  color: #383838;
  background: #f2f2f2;
  line-height: 1.5em;
  font-size: 0.8em;
  border-radius: 0.3em;
  text-align: center;
  padding: 0.5em 1em;
  cursor: pointer;
}

.back-btn:hover {
  background-color: #e89700;
  color: #fff;
}

/*search bar*/

.go-search .input-group {
  border: 1px solid #e3e3e3;
  border-radius: 0.3em;
}

.go-search .form-control {
  padding: 1.5em;
  font-size: 0.9em;
  border-radius: 0.3em !important;
  border: 0.3em;
  background-color: #fff;
}

.go-search .form-control,
.go-search .btn.btn-outline-light,
.btn.btn-search:focus {
  box-shadow: none;
  outline: none;
}

.go-search .btn.btn-outline-light {
  padding: 1.5em;
  font-size: 0.8em;
  border: 0;
  background-color: #fafafa;
  line-height: 2em;
}

.btn.btn-search {
  padding: 20px 40px;
  font-size: 12px;
  background-color: #ffac10;
  color: #ffffff !important;
  font-weight: 600;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
}

.btn.btn-search:hover {
  background-color: #e89700;
  color: #fff;
}

.box-rent {
  height: 25em;
  position: relative;
}

.rent-space {
  position: absolute;
  top: 10%;
  left: -6%;
  width: 35em;
  height: 20em;
}

/*custom radio button*/

.custom_radio_btn {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-right: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.custom_radio_btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom_radio_btn .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #d2d2d2;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.custom_radio_btn:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.custom_radio_btn input:checked ~ .checkmark {
  background-color: #ffac10;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom_radio_btn .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.custom_radio_btn input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.custom_radio_btn .checkmark:after {
  top: 4px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

/*custom checkbox*/

.custom_checkbox_div {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom_checkbox_div input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom_checkbox_div .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #d2d2d2;
}

/* On mouse-over, add a grey background color */
.custom_checkbox_div:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom_checkbox_div input:checked ~ .checkmark {
  background-color: #ffac10;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom_checkbox_div .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom_checkbox_div input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom_checkbox_div .checkmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*full modal*/

.modal.modal-fullscreen .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  max-width: none;
}

.modal.modal-fullscreen .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
  border: none;
}

.modal.modal-fullscreen .modal-header {
  border-bottom: 0;
}
.modal.modal-fullscreen .modal-body {
  padding: 16em 0;
}

.modal.modal-fullscreen .modal-body .go-search .input-group {
  border-radius: 0.3em;
  max-width: 750px;
  margin: 0px auto;
  font-size: 1em;
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.05);
  border: 0;
}

.modal.modal-fullscreen .modal-body .form-group .form-control:focus {
  box-shadow: none;
}

.picker__container__tab button.active {
  color: white;
  border-bottom: 2px solid #0d181e;
}

.start-date .picker__container__tab {
  margin: 0px;
}
.picker__container__tab {
  margin: 0px;
}
.picker__container__tab button.active {
  background: #e6c243;
}

.picker-input__icon {
  position: absolute;
  top: 34px;
  left: 13px;
  font-size: 1.5em;
}

.picker-input__text {
  display: block;
  width: 100%;
  padding: 1em 1em;
  font-size: 0.85em;
  border: 0 !important;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.picker__container__calonly {
  width: 100%;
}
.picker__container {
  margin-top: 1em;
  background: #f5f4f2;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
  display: flex;
  padding: 1em;
}
.calendar__head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
}
.calendar__head--prev {
  display: flex;
}
.calendar__head--next {
  display: flex;
}
.calendar__head--button {
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  border:0;
}
.calendar__head--title {
  padding-top: 0.5em;
  font-size: 1.5em;
}
.calendar__body--table tr th {
  padding-left: 1.3em;
  height: 30px;
  vertical-align: top;
}
.big-calendar .calendar__body--table tr th {
  padding-left: 1.9em;
}
.big-calendar .calendar__body--table tr td {
  height: 30px;
  padding-left: 2.1em;
  vertical-align: middle;
  padding-top: 1em;
}
.big-calendar .calendar__body--table tr td span {
  vertical-align: sub;
  color: #ffac10;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20px;
}
.calendar__body--table tr td {
  height: 30px;
  padding-left: 1.5em;
  vertical-align: middle;
}
.calendar__body {
  display: flex;
  flex-direction: column;
}

.mt-50 {
  margin-top: 1em;
}

.suggestions-container {
  width: 300px;
  -webkit-box-shadow: 0 4px 10px rgba(53, 60, 72, 0.1);
  box-shadow: 0 4px 10px rgba(53, 60, 72, 0.1);
  z-index: 9999;
  background-color: #fff;
  margin-top: 0.5rem;
  position: absolute;
  width: 280px;
  right: 27em;
  top: 4em;
}
.suggestions-container ul li {
  cursor: pointer;
  color: #4d4d4d;
}
.suggestions-container ul li:hover {
  background-color: #ffac10;
  color: #fff;
}
.suggestions-container ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  text-align: start;
}
.suggestions-container ul li span {
  padding: 0.8rem;
  letter-spacing: 0.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: lowercase;
  display: block;
}
.txt-overflow {
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.highlight-txt {
  font-size: 1.1em;
  opacity: 1;
  color: #ffac10;
  font-weight: 600;
  border-left: 5px solid #ffac10;
  padding: 0 0.2em;
  margin-bottom: 0;
}

/*New Chat Css*/
img {
  max-width: 100%;
}
.inbox_people {
  background: #efefef none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
}
.inbox_msg {
  background: rgb(241, 241, 241);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.3em;
  clear: both;
  overflow: hidden;
}
.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch {
  padding: 10px 29px 10px 10px;
  overflow: hidden;
}
.srch_bar input {
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
  border-color: transparent;
}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #4d4d4d;
  margin: 0 0 8px 0;
}
.chat_ib h5 span {
  font-size: 13px;
  float: right;
}
.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}
.chat_list {
  margin: 0;
  padding: 18px 16px 10px;
  background-color: #fff;
  margin-bottom: 1em;
  border-radius: 0.3em;
}
.inbox_chat {
  height: 400px;
  overflow-y: scroll;
  padding: 1em;
}

.active_chat {
  background: #ffac10;
  border-radius: 0.3em;
}
.active_chat .chat_ib p {
  color: #fff;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received_withd_msg p {
  background: #fff none repeat scroll 0 0;
  border-radius: 3px;
  color: #4d4d4d;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg {
  width: 57%;
}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
  background: #e9e8e8;
}

.sent_msg p {
  background: #ffac10 none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
  outline: none;
}

.type_msg {
  position: relative;
  margin-top: 1em;
  border: 1px solid transparent;
  border-radius: 0.3em;
  margin-bottom: 1em;
  font-size: 0.9em;
  font-weight: 500;
  background: #fff;
  padding-left: 1em;
}
.msg_send_btn {
  background: #ffac10 none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
  margin-right: 1em;
}
.messaging {
  padding: 0 0 50px 0;
}
.msg_history {
  height: 400px;
  overflow-y: auto;
}


.review-space{
  width: 3.42em;
  margin-top: 1.71em;
  margin-bottom: 1.71em;
}

h1.section-head {
  font-size: 1.71em;
  line-height: 1.25;
  margin: 0;
  text-transform: capitalize;
  padding-top: 2px;
  padding-bottom: 2px;
}

p.small-line {
  margin: 1em 0;
  border-bottom: 1px solid #e5e5e5;
  width: 100px;
}

.margin-top-xl{
  margin-top: 2em;
}

.user-booking-list .text-align-center{
  text-align: center;
}

.user-booking-list .text-align-right{
  text-align: right;
}